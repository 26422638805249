<template>
  <v-container fluid>
    <v-list-item three-line>
      <v-list-item-content>
        <p class="display-1 text--primary">
          {{ $t("menu.proccess") }}
        </p>
      </v-list-item-content>
    </v-list-item>
    <v-data-table
      :headers="headers"
      :search="search"
      :items="proccess"
      :loading="loading"
      :no-results-text="$t('general.ui.not_search_found')"
      :no-data-text="$t('general.ui.not_data_found')"
      :loading-text="$t('general.notification.loadData')"
      :footer-props="{
        itemsPerPageText: $t('general.table.itemsPerPageText'),
        pageText: '{0}-{1} ' + $t('general.table.pageText') + ' {2}',
      }"
    >
      <template v-slot:item.processType="{ item }">
        {{ $t(`proccess.select.${item.processType}`) }}
      </template>
      <template v-slot:item.integrationEndpoint="{ item }">
        <v-tooltip bottom max-width="300">
          <template v-slot:activator="{ on, attrs  }">
            <v-icon
              v-on="on"
              class="mr-4"
              color="error"
              v-if="item.integrationEndpoint && item.integrationEndpoint !== '-' && item.integrationEndpoint !== null "
            >
              mdi-webhook
            </v-icon>
          </template>
          <span>
            {{ $t('proccess.tooltips.info') }}
          </span>
        </v-tooltip>
      </template>

      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-row class="d-flex justify-space-between align-baseline">
            <v-col class="pl-0" lg="4" md="4" sm="6" xl="4" cols="6">
              <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replace" v-on:keyup.ctrl.86="replace"
                dense
                filled
                rounded
                color="primary"
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('general.titles.filter')"
                :clearable="true"
                single-line
                hide-details
              />
            </v-col>
            <v-spacer></v-spacer>
            <v-col lg="4" md="4" sm="4" xl="4" cols="4" class="text-end">
              <v-btn
                small
                tile
                :elevation="0"
                color="gray"
                style="border-radius: 1.5em;"
                @click="addProcess"
                v-if="check([{ domain: 'Process', permisions: ['Write'] }])"
              >
                <v-icon small color="neuter" left>mdi-plus</v-icon>
                {{ $t("general.buttons.new") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              v-if="check([{ domain: 'Process', permisions: ['Update'] }])"
              color="neuter"
              class="mr-2"
              small
              @click="editItem(item)"
            >
              mdi-square-edit-outline
            </v-icon>
          </template>
          <span>
              {{ $t("general.buttons.edit") }}
              <span style="font-size: 12px; color: #AAAAAA">
                {{ $t("menu.proccess") }}
              </span>
            </span>
        </v-tooltip>
        <span
          v-if="!item.integrationEndpoint || item.integrationEndpoint === '-'"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                v-if="check([{ domain: 'Process', permisions: ['Delete'] }])"
                color="neuter"
                small
                @click="deleteItemDialog(item)"
              >
                mdi-trash-can-outline
              </v-icon>
            </template>
            <span>
                {{ $t("general.buttons.delete") }}
                <span style="font-size: 12px; color: #AAAAAA">
                  {{ $t("menu.proccess") }}
                </span>
              </span>
          </v-tooltip>
        </span>
      </template>
    </v-data-table>
    <v-row justify="center">
      <v-dialog v-model="showDialog" persistent width="600px">
        <v-card>
          <v-card-title class="headline secondary t-bw-secondary--text">{{ title }}</v-card-title>
          <v-card-text class="pa-6">{{ dialogText }}</v-card-text>
          <v-card-actions class="pa-6">
            <v-spacer></v-spacer>
            <v-btn small text color="neuter" @click="dialogClose">
              {{ $t("general.buttons.cancel") }}
            </v-btn>
            <v-btn small elevation="0" class="t-bw-error--text" color="error" @click="deleteItem">
              {{ $t("general.buttons.confirm") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import i18n from "@/plugins/i18n";
import { mapActions, mapGetters } from "vuex";
import LanguajeService from "../../../services/LanguajeService";
import _ from "lodash";
import PermisionService from "../../../services/PermisionService";

export default {
  data: () => ({
    profile: null,
    search: "",
    showDialog: false,
    title: "Información",
    dialogText: "Añada un texto a este dialogo",
    deletingItem: {},
  }),

  async created() {
    this.profile = JSON.parse(localStorage.getItem("profile"));
    await this.fetchListProccess([this.profile, this.$toast]);
  },

  computed: {
    processSelected: {
      get() {
        return this.$store.getters["proccess/procces"];
      },
      set(val) {
        this.$store.commit("proccess/SET_PROCESS", val);
      },
    },
    loading: {
      get() {
        return this.$store.getters["proccess/loading"];
      },
      set(val) {
        this.$store.commit("proccess/SET_LOADING", val);
      },
    },
    ...mapGetters({
      proccess: "proccess/proccess",
    }),
    headers: () => [
      { text: i18n.t("proccess.fields.name"), value: "proccess_name" },
      { text: i18n.t("proccess.fields.stages"), value: "sdt_to_show_name" },
      { text: i18n.t("proccess.fields.processType"), value: "processType" },
      { text: i18n.t("proccess.fields.info"), value: "integrationEndpoint", align: "center", sortable: false },
      {
        text: i18n.t("general.fields.action"),
        value: "action",
        width: 100,
        sortable: false,
        align: "end",
      },
    ],
  },

  methods: {
    ...mapActions({
      fetchListProccess: "proccess/fetchListProccess",
      fetchProcessById: "proccess/getProcessById",
      deleteProccess: "proccess/deleteProccess",
    }),
    check(permisions) {
      return PermisionService.check(permisions);
    },

    addProcess() {
      this.$store.commit("proccess/SET_ORIGINAL_STATE_PROCESS");
      this.$router.push("/processAdd");
    },

    async editItem(item) {
      this.loading = true;
      this.editedIndex = this.proccess.indexOf(item);
      await this.fetchProcessById([item.id, this.$toast]).finally(() => {
        this.loading = false;
      });

      localStorage.setItem('process:version', item.processVersionId)
      localStorage.setItem('process:type', item.processType)
      await this.$router.push({ path: "/processAdd" });
    },

    deleteItemDialog(item) {
      this.deletingItem = item;
      this.dialogShow({
        title: i18n.t("general.titles.alert"),
        dialogText: i18n.t("general.notification.deleteItems"),
      });
    },

    deleteItem() {
      this.dialogClose();
      this.deleteProccess([this.deletingItem.id, this.$toast]).finally(() => {
        this.fetchListProccess([this.profile, this.$toast]);
      });
    },

    dialogShow(params) {
      this.title = params.title;
      this.dialogText = params.dialogText;
      this.showDialog = true;
    },
    dialogClose() {
      this.showDialog = false;
    },
    replace(event){
      event.currentTarget.value =  event.currentTarget.value.toString().replaceAll('"','')
      this.search =  event.currentTarget.value.toString().replaceAll('"','')
    }
  },
};
</script>

<style scoped>
.v-stepper__step {
  padding: 10px !important;
}
</style>
